import React from 'react'
import { EloIconProps } from '../types'

export const EloBundleIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.8666' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M7.099 21.227V10.7811C7.099 10.6185 7.18972 10.4696 7.33414 10.395L16.7858 5.51336C16.9109 5.44876 17.0595 5.44876 17.1846 5.51336L26.6363 10.395C26.7807 10.4696 26.8714 10.6185 26.8714 10.7811V21.227C26.8714 21.3882 26.7822 21.5361 26.6396 21.6113L17.188 26.5968C17.0611 26.6638 16.9094 26.6638 16.7825 26.5968L7.33082 21.6113C7.18823 21.5361 7.099 21.3882 7.099 21.227Z'
      fill='#FAFAFC'
      stroke='#21282E'
      strokeWidth='0.295111'
      strokeLinejoin='round'
    />
    <path d='M26.8715 10.5164L16.9852 15.8941V26.6495L26.8715 21.5434V10.5164Z' fill='#2BFF99' />
    <path d='M16.9852 15.8941L7.099 10.5164V21.5434L16.9852 26.6495V15.8941Z' fill='#21282E' />
    <path
      d='M7.099 21.227V10.7811C7.099 10.6185 7.18972 10.4696 7.33414 10.395L16.7858 5.51336C16.9109 5.44876 17.0595 5.44876 17.1846 5.51336L26.6363 10.395C26.7807 10.4696 26.8714 10.6185 26.8714 10.7811V21.227C26.8714 21.3882 26.7822 21.5361 26.6396 21.6113L17.188 26.5968C17.0611 26.6638 16.9094 26.6638 16.7825 26.5968L7.33082 21.6113C7.18823 21.5361 7.099 21.3882 7.099 21.227Z'
      stroke='#21282E'
      strokeWidth='0.67'
      strokeLinejoin='round'
    />
    <path d='M16.9852 15.8398L26.8715 10.6251' stroke='#21282E' strokeWidth='0.67' strokeLinejoin='round' />
    <path d='M7.099 10.5708L16.9852 15.8941V26.5951' stroke='#21282E' strokeWidth='0.67' strokeLinejoin='round' />
    <path d='M22.0021 24.044V13.087L12.3126 7.86963' stroke='#21282E' strokeWidth='0.67' strokeLinejoin='round' />
  </svg>
)
