import React from 'react'
import { EloIconProps } from '../types'

export const EloCertificateIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M24.1185 5.1115H8.48172C7.85187 5.1115 7.34128 5.62209 7.34128 6.25194V25.7479C7.34128 26.3778 7.85187 26.8884 8.48172 26.8884H24.1185C24.7483 26.8884 25.2589 26.3778 25.2589 25.7479V6.25194C25.2589 5.62209 24.7483 5.1115 24.1185 5.1115Z'
      fill='#F3F5F8'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path
      d='M25.264 6.25194V12.6768H7.33631V6.25194C7.33631 5.62261 7.84713 5.1115 8.47676 5.1115L24.1239 5.1115C24.7532 5.1115 25.2643 5.62231 25.2643 6.25194H25.264Z'
      fill='#21282E'
    />
    <path
      d='M12.2968 23.3333H20.2968'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path d='M17.8214 20.4772V15.2301H14.7737V20.4772L16.2976 19.2727L17.8214 20.4772Z' fill='#21282E' />
    <path
      d='M20.2506 14.6836L18.7484 14.861L18.6331 16.3689L17.2438 15.7716L16.3899 17.0199L15.4853 15.8077L14.1214 16.4619L13.9442 14.96L12.4361 14.8445L13.0334 13.4551L11.7851 12.6012L12.9973 11.6966L12.3431 10.333L13.8453 10.1556L13.9605 8.64742L15.3499 9.24475L16.2038 7.99675L17.1084 9.2086L18.4722 8.55468L18.6494 10.0566L20.1576 10.1719L19.5602 11.5612L20.8085 12.4151L19.5964 13.3197L20.2506 14.6836Z'
      fill='#2BFF99'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
  </svg>
)
