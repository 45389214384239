import React from 'react'
import { EloIconProps } from '../types'

export const EloCheckoutIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.3333' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M9.86023 22.5645L6.92805 8.57395C6.77654 7.85106 6.13907 7.33334 5.40048 7.33334H4.90369'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4261 22.5646L10.7411 19.3256'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M28.1986 11.6543L25.6986 18.538C25.5258 19.0137 25.0737 19.3305 24.5676 19.3305H17.3441H9.18059L7.05081 9.14778L27.7549 10.9584C28.0955 10.9882 28.3153 11.3329 28.1986 11.6543Z'
      fill='#F3F5F8'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.5511 22.4895H23.2595'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='11.355' cy='22.5646' r='1.65446' fill='#21282E' />
    <circle cx='23.5816' cy='22.5568' r='1.65446' fill='#21282E' />
    <circle cx='9.84067' cy='22.5646' r='1.65446' fill='#21282E' />
    <path
      d='M16.7394 14.0856C16.7394 13.8957 16.8933 13.7418 17.0832 13.7418H20.834C21.0238 13.7418 21.1778 13.8957 21.1778 14.0856V18.8334C21.1778 19.0233 21.0238 19.1772 20.834 19.1772H17.0832C16.8933 19.1772 16.7394 19.0233 16.7394 18.8334V14.0856Z'
      fill='#21282E'
      stroke='#21282E'
      strokeWidth='0.312575'
    />
    <circle cx='22.0852' cy='22.5646' r='1.65446' fill='#21282E' />
    <circle cx='9.83117' cy='22.5609' r='0.780355' fill='#F3F5F8' />
    <circle cx='22.0995' cy='22.5608' r='0.780355' fill='#F3F5F8' />
    <path
      d='M24.9674 10.709L22.2303 18.5254C22.0614 19.008 21.6059 19.3311 21.0947 19.3311H13.8815'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <mask id='path-14-inside-1_2706_97842' fill='white'>
      <rect x='11.2149' y='11.541' width='6.37021' height='7.94054' rx='0.50012' />
    </mask>
    <rect
      x='11.2149'
      y='11.541'
      width='6.37021'
      height='7.94054'
      rx='0.50012'
      fill='#2BFF99'
      stroke='#21282E'
      strokeWidth='1.33333'
      mask='url(#path-14-inside-1_2706_97842)'
    />
  </svg>
)
