import React from 'react'
import { EloIconProps } from '../types'

export const EloOnlineCourseIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.5' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M8.46088 19.5992L8.46088 14.8267L17.0928 18.7992L17.0932 19.4075L17.0959 23.5992C9.93203 23.5992 8.35427 20.9325 8.46088 19.5992Z'
      fill='#21282E'
      stroke='#21282E'
      strokeWidth='0.666667'
    />
    <path
      d='M25.0913 19.6596L25.0913 14.9592L17.0988 18.8717L17.0959 23.5992C23.7291 23.5992 25.19 20.9728 25.0913 19.6596Z'
      fill='#2BFF99'
      stroke='#21282E'
      strokeWidth='0.666667'
    />
    <path
      d='M5.11336 13.2881L16.7574 18.6424C17.0115 18.7593 17.3042 18.758 17.5572 18.6389L28.9367 13.2846C29.6626 12.943 29.6626 11.9104 28.9367 11.5688L17.5572 6.21448C17.3042 6.09542 17.0115 6.09413 16.7574 6.21096L5.11336 11.5653C4.37732 11.9037 4.37732 12.9497 5.11336 13.2881Z'
      fill='#FAFAFC'
      stroke='#21282E'
      strokeWidth='0.666667'
    />
    <path d='M19.3725 13.3867L23.6428 15.7867V22.1867' stroke='#21282E' strokeWidth='0.666667' strokeLinejoin='round' />
    <path
      d='M22.7411 22.0075C22.6003 22.1214 22.5185 22.2928 22.5185 22.4739V25.92C22.5185 26.2514 22.7871 26.52 23.1185 26.52H24.1668C24.4982 26.52 24.7668 26.2514 24.7668 25.92V22.4739C24.7668 22.2928 24.685 22.1214 24.5443 22.0075L24.0201 21.5833C23.8 21.4052 23.4853 21.4052 23.2652 21.5833L22.7411 22.0075Z'
      fill='#FAFAFC'
      stroke='#21282E'
      strokeWidth='0.666667'
    />
    <path
      d='M20.3215 12.4267C20.3215 13.3103 18.8344 14.0267 17.0001 14.0267C15.1657 14.0267 13.6787 13.3103 13.6787 12.4267C13.6787 12.4221 13.6888 11.2166 13.6788 11.7867C13.6942 10.9093 15.1753 10.8267 17.0001 10.8267C18.8223 10.8267 20.3018 10.9111 20.3213 11.7867C20.3213 11.3241 20.3215 12.4208 20.3215 12.4267Z'
      fill='#21282E'
    />
    <path
      d='M19.9881 11.7867C19.9881 12.0277 19.7744 12.343 19.204 12.6178C18.6572 12.8812 17.8784 13.0534 17.0001 13.0534C16.1218 13.0534 15.343 12.8812 14.7962 12.6178C14.2258 12.343 14.012 12.0277 14.012 11.7867C14.012 11.5457 14.2258 11.2304 14.7962 10.9556C15.343 10.6922 16.1218 10.52 17.0001 10.52C17.8784 10.52 18.6572 10.6922 19.204 10.9556C19.7744 11.2304 19.9881 11.5457 19.9881 11.7867Z'
      fill='#2BFF99'
      stroke='#21282E'
      strokeWidth='0.666667'
    />
  </svg>
)
