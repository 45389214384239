import React from 'react'
import { EloIconProps } from '../types'

export const EloDownloadIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M26.3334 16C26.3334 21.5229 21.8562 26 16.3334 26C10.8105 26 6.33335 21.5229 6.33335 16C6.33335 10.4772 10.8105 6.00001 16.3334 6.00001C21.8562 6.00001 26.3334 10.4772 26.3334 16Z'
      fill='#21282E'
      stroke='black'
      strokeWidth='1.33333'
    />
    <path
      d='M13.6667 17.2894V9.87454C13.6667 9.78659 13.738 9.71529 13.8259 9.71529H16.3333H18.8407C18.9287 9.71529 19 9.78659 19 9.87454V17.2894H21.2766C21.4193 17.2894 21.4899 17.4626 21.388 17.5625L16.4448 22.4061C16.3829 22.4667 16.2838 22.4667 16.2219 22.4061L11.2786 17.5625C11.1767 17.4626 11.2474 17.2894 11.3901 17.2894H13.6667Z'
      fill='#2BFF99'
    />
  </svg>
)
