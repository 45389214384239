import React from 'react'
import { EloIconProps } from '../types'

export const EloLicenseCodeIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.6667' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M15.0907 14.1689L18.7268 17.805C18.8136 17.8918 18.8136 18.0326 18.7268 18.1191L17.3594 19.4865C17.3076 19.5383 17.2332 19.5614 17.1609 19.5475L15.7194 19.2734C15.5653 19.2441 15.4305 19.3792 15.4596 19.5333L15.7336 20.9748C15.7473 21.0471 15.7244 21.1214 15.6726 21.1733L15.157 21.6889C15.1052 21.7407 15.0308 21.7638 14.9585 21.7499L13.517 21.4758C13.363 21.4465 13.2281 21.5816 13.2572 21.7357L13.5313 23.1772C13.5449 23.2494 13.5221 23.3238 13.4702 23.3757L12.4693 24.3766C12.4308 24.4151 12.3798 24.4379 12.3253 24.4412L8.45837 24.6717C8.38969 24.6758 8.32718 24.6485 8.28386 24.6028C8.24326 24.56 8.21953 24.5011 8.2234 24.4367L8.45392 20.5697C8.45718 20.5155 8.48029 20.4643 8.51851 20.4257L14.776 14.1683C14.8628 14.0814 15.0041 14.082 15.0907 14.1689Z'
      fill='#F3F5F8'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path d='M8.29614 24.6048L15.7036 16.8048L8.29614 24.6048Z' fill='#2BFF99' />
    <path d='M8.29614 24.6048L15.7036 16.8048' stroke='#21282E' strokeWidth='0.666667' strokeMiterlimit='10' />
    <path
      d='M19.9292 18.677C23.0835 18.677 25.6406 16.1199 25.6406 12.9656C25.6406 9.81128 23.0835 7.2542 19.9292 7.2542C16.7749 7.2542 14.2178 9.81128 14.2178 12.9656C14.2178 14.4437 14.7792 15.7906 15.7005 16.8047C16.7453 17.9549 18.2529 18.677 19.9292 18.677Z'
      fill='#F3F5F8'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path
      d='M23.9678 17.0041C26.1983 14.7736 26.1983 11.1576 23.9678 8.92709C21.7372 6.69657 18.1212 6.69657 15.8907 8.92709L23.9678 17.0041Z'
      fill='#21282E'
    />
    <path
      d='M21.5875 12.7344C22.3755 12.7344 23.0144 12.0955 23.0144 11.3075C23.0144 10.5194 22.3755 9.88049 21.5875 9.88049C20.7994 9.88049 20.1605 10.5194 20.1605 11.3075C20.1605 12.0955 20.7994 12.7344 21.5875 12.7344Z'
      fill='#2BFF99'
    />
  </svg>
)
