import React from 'react'
import { EloIconProps } from '../types'

export const EloTicketIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.8333' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M25.9947 15.5402C25.9947 16.9342 27.1245 18.0643 28.5186 18.0643V21.4139H5.41666V18.0643C6.81043 18.0643 7.94051 16.9342 7.94051 15.5402C7.94051 14.1461 6.81043 13.0163 5.41666 13.0163V9.66667H28.5186V13.0163C27.1245 13.0163 25.9947 14.1464 25.9947 15.5402Z'
      fill='#F3F5F8'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path
      d='M19.1345 9.66666V21.4139H5.33331V18.0643C6.72709 18.0643 7.85716 16.9342 7.85716 15.5401C7.85716 14.1461 6.72709 13.0163 5.33331 13.0163V9.66666H19.1345Z'
      fill='#2BFF99'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path
      d='M13.1773 18.8136C15.0809 18.8136 16.6241 17.2704 16.6241 15.3667C16.6241 13.4631 15.0809 11.9199 13.1773 11.9199C11.2736 11.9199 9.73045 13.4631 9.73045 15.3667C9.73045 17.2704 11.2736 18.8136 13.1773 18.8136Z'
      fill='#21282E'
    />
    <path
      d='M13.3266 12.071C15.0771 12.1486 16.4771 13.5972 16.4771 15.3664C16.4771 17.1356 15.0771 18.5842 13.3266 18.6618V12.071Z'
      fill='#F3F5F8'
    />
    <path
      d='M13.4747 12.2298C15.0735 12.3797 16.3289 13.729 16.3289 15.3664C16.3289 17.0037 15.0735 18.3533 13.4747 18.503V12.2295M13.1784 11.9193V18.8129C15.0821 18.8129 16.6252 17.2698 16.6252 15.3661C16.6252 13.4624 15.0821 11.9193 13.1784 11.9193Z'
      fill='#21282E'
    />
    <path d='M20.9674 11.5396V19.5396H22.9674V11.5396H20.9674Z' fill='#21282E' />
    <circle cx='13.2' cy='15.4' r='3.13333' stroke='#21282E' strokeWidth='0.666667' />
  </svg>
)
