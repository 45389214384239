import React from 'react'
import { EloIconProps } from '../types'

export const EloMembershipIcon: React.FC<EloIconProps> = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.3333' cy='16' r='16' fill='#FAFAFC' />
    <path
      d='M26.3271 9.29169H6.93366C6.30381 9.29169 5.79321 9.80228 5.79321 10.4321V21.4238C5.79321 22.0537 6.30381 22.5643 6.93366 22.5643H26.3271C26.957 22.5643 27.4676 22.0537 27.4676 21.4238V10.4321C27.4676 9.80228 26.957 9.29169 26.3271 9.29169Z'
      fill='#F3F5F8'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path
      d='M6.93366 9.29183H13.2048V22.5644H6.93366C6.30432 22.5644 5.79321 22.0536 5.79321 21.424V10.432C5.79321 9.80265 6.30403 9.29153 6.93366 9.29153V9.29183Z'
      fill='#2BFF99'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
    />
    <path d='M14.2253 14.3496H27.4677' stroke='#21282E' strokeWidth='0.666667' strokeMiterlimit='10' />
    <path
      d='M15.4282 11.3171H10.9814C10.5343 11.3171 10.1719 11.6795 10.1719 12.1265V16.5734C10.1719 17.0204 10.5343 17.3828 10.9814 17.3828H15.4282C15.8752 17.3828 16.2377 17.0204 16.2377 16.5734V12.1265C16.2377 11.6795 15.8752 11.3171 15.4282 11.3171Z'
      fill='#21282E'
    />
    <path
      d='M15.1229 17.0608C15.1229 16.0015 14.2643 15.1426 13.2047 15.1426C12.1452 15.1426 11.2865 16.0012 11.2865 17.0608H15.1226H15.1229Z'
      fill='#FAFAFC'
    />
    <path d='M15.1227 17.061C15.1227 16.0017 14.264 15.1427 13.2045 15.1427V17.061H15.1227Z' fill='#E4E1DD' />
    <path
      d='M13.2043 14.9321C13.8392 14.9321 14.3539 14.4174 14.3539 13.7824C14.3539 13.1475 13.8392 12.6328 13.2043 12.6328C12.5694 12.6328 12.0547 13.1475 12.0547 13.7824C12.0547 14.4174 12.5694 14.9321 13.2043 14.9321Z'
      fill='#E4E1DD'
    />
    <path
      d='M15.6803 19.7944H23.4118'
      stroke='#21282E'
      strokeWidth='0.666667'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <circle cx='24.5334' cy='11.8667' r='1.2' fill='#21282E' />
  </svg>
)
